import { css } from "@emotion/react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PolicyPage = () => (
  <Layout>
    <SEO title="托特｜隱私權政策" />
    <div
      css={css`
        margin-top: 80px;
        margin-bottom: 40px;
      `}
    >
      <h1>隱私權政策與用戶協議</h1>
      <p>最後修改日期：2022 年 3 月 26 日</p>
      <p>
        在使用托特 thoth (以下也稱“本平台”以及“我們”)
        之前，請先詳閱以下隱私條款以及使用規範。為確保平台運作順利，當你註冊成為本平台成為用戶時，代表你同意了以下協議。
      </p>
      <p>
        本平台保有不定時修改協議的權利。我們盡可能使用簡單易懂的方式解釋以下條款及規範，並於更改時，不另行通知用戶。
        如果有任何疑問，請聯繫 support@thoth.tw 尋求協助。
      </p>

      <h2>一、包含範圍</h2>
      <p>
        隱私權政策的範圍包含了本平台的各項產品。產品包含網站
        (通常已thoth.tw結尾)、IOS 以及 Android
        APP。隱私權政策不適用於本平台外的相關連結網站。
      </p>
      <p>隱私權政策的內容關注用戶在本平台上的個人識別資料以及創造的內容。</p>

      <h2>二、用戶內容授權、蒐集、處理及利用</h2>
      <p>
        托特允許用戶使用 Facebook 與 Google
        兩種社交帳號註冊及登入本平台，登入時我們會依據用戶的授權從社交網站獲取用戶資訊。需要注意的是，由於蘋果公司對
        IOS APP 的政策，我們必須在 IOS APP
        內提供用戶使用蘋果登入的機制。我們在網站及 IOS/Android APP 都支援
        Facebook 或是 Google 登入。如果你使用了蘋果登入，此帳戶將只能用於 IOS
        APP。
      </p>
      <p>
        登入後，本平台允許用戶發佈自己創做的內容，並且為智慧財產權所保護。用戶內容在發佈時也代表同意了以下規定：
        <ul>
          <li>
            本平台擁有所有用戶內容的授權。我們可以以任何形式使用用戶在平台內所發佈的內容，但在公開用戶內容時必須以清楚的方式表示內容是由內容擁有者製造。
          </li>
          <li>本平台保有刪除、修改用戶內容的權利。</li>
          <li>本平台保有接受、拒絕用戶內容的權利。</li>
          <li>用戶的聊天內容為隱私資料，本平台會確保資料不被公開。</li>
          <li>
            如果擔心內容未被儲存或被刪除的風險，用戶有責任自行備份內容。平台有備份用戶內容的權益，但沒有義務幫用戶備份內容。
          </li>
          <li>
            為了確保本平台的內容品質，我們會適當地監管用戶內容。嚴禁各種侮辱、傷害、暴力、色情等影響他人的內容出現。同時也提醒用戶在發文或與用戶交流時保持言論友善。
          </li>
          <li>
            用戶禁止安裝任何對本平台產生負面影響甚至造成傷害的外掛等第三方系統，也禁止使用自動化工具或程式獲取本平台內容。
          </li>
          <li>
            當用戶使用本平台時，我們會可能會收集用戶使用平台時的相關紀錄，包含連線設備的位置、時間、裝置以及瀏覽行為等。這些紀錄僅供內部應用及改善產品，我們不會公開相關紀錄。
          </li>
          <li>本平台保有隨時新增、刪減相關規則的權利。</li>
        </ul>
      </p>

      <h2>三、資料之保護</h2>
      <p>
        <ul>
          <li>搜集資料目的：提供平台相關服務、用戶興趣分析以及會員管理。</li>
          <li>搜集資料類別：用戶姓名、Email、職稱學歷、興趣休閒愛好、發文及留言。</li>
          <li>資料查閱與刪除：用戶可就個人資料請求查詢修改、停止搜集、刪除。用戶可以發信至 support@thoth.tw 行使上述權利。</li>
        </ul>
      </p>
      <p>
        大部分的用戶個人資料包含名稱、職稱學歷、自我介紹將會被公開，但用戶有權隨時修改其內容。
      </p>
      <p>
        本平台在沒有用戶許可下，不允許公開、交換、出租或出售任何用戶被保護的資料，如果有法律依據或合約義務者則不在此限。
      </p>
      <p>
        為順利使用本平台服務，用戶需要同意本平台在客戶端內放置Cookie。客戶端包含用戶使用電腦的瀏覽器或著是用戶使用的
        APP。
      </p>
      <h2>四、服務終止</h2>
      <p>
        當本平台進行升級、維護以及遇到其他臨時問題時，用戶可能暫時無法使用本平台。
        本平台保有隨時終止用戶使用的權利。除此之外，用戶能自行暫停帳戶或者是聯繫本平台來終止帳戶使用。
      </p>
    </div>
  </Layout>
)

export default PolicyPage
